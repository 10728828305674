import React, { useContext, useState } from "react";
import { ListGroup, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import useWindowSize from "../../../../hooks/useWindowSize";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import NavSearch from "./NavSearch";
import { useSelector } from "react-redux";

const NavLeft = () => {
  const windowSize = useWindowSize();
  const { list } = useSelector((state) => state.assetList);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);

  const [activeAssetID, setactiveAssetID] = useState(
    window.localStorage.getItem("assetID")
  );

  const configContext = useContext(ConfigContext);
  const { rtlLayout } = configContext.state;
  let dropdownRightAlign = false;
  if (rtlLayout) {
    dropdownRightAlign = true;
  }

  let navItemClass = ["nav-item"];
  if (windowSize.width <= 575) {
    navItemClass = [...navItemClass, "d-none"];
  }

  const setActiveAsset = (id) => {
    localStorage.setItem("assetID", id);
    setactiveAssetID(id);
  };

  const getActiveAssetInfo = () => {
    let index = list.findIndex((item) => item._id === activeAssetID);
    if (index > -1) {
      return list[index].assetName;
    }
    return "Active Assets";
  };

  if (!activeAssetID && list.length > 0) {
    setActiveAsset(list[0]._id);
  }

  return (
    <React.Fragment>
      <ListGroup
        style={{ display: "none" }}
        as="ul"
        bsPrefix=" "
        className="navbar-nav mr-auto"
      >
        <ListGroup.Item as="li" bsPrefix=" " className={navItemClass.join(" ")}>
          <Dropdown
            onToggle={() => setisDropdownOpen(!isDropdownOpen)}
            show={isDropdownOpen}
            alignRight={dropdownRightAlign}
          >
            <Dropdown.Toggle variant={"link"} id="dropdown-basic">
              {getActiveAssetInfo()}
            </Dropdown.Toggle>
            <ul>
              <Dropdown.Menu>
                {Array.isArray(list) &&
                  list.map((item, idx) => {
                    return (
                      <li
                        onClick={() => {
                          setisDropdownOpen(!isDropdownOpen);
                          setActiveAsset(item._id);
                        }}
                        key={`drop-${idx}`}
                      >
                        <Link to="#" className="dropdown-item ">
                          {item.assetName}
                        </Link>
                      </li>
                    );
                  })}
              </Dropdown.Menu>
            </ul>
          </Dropdown>
        </ListGroup.Item>
        <ListGroup.Item
          style={{ display: "none" }}
          as="li"
          bsPrefix=" "
          className="nav-item "
        >
          <NavSearch windowWidth={windowSize.width} />
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavLeft;
