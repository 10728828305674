import { BASE_API_ENDPOINT } from "../../../src/config/constant";
import axios from "../../utils/axios";
import {
  ALL_USERS_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REGISTER_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
} from "../constants/constants";

//actual
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const { data } = await axios.get(`/auth/users`);

    dispatch({ type: ALL_USERS_SUCCESS, payload: data.users });
  } catch (error) {
    dispatch({ type: ALL_USERS_FAIL, payload: error?.message });
  }
};

export const getUpdatedAllUsers =
  (entry = 5, index = 0, search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_ALL_USERS_REQUEST" });
      const { data } = await axios.get(
        `/auth/get-users?entry=${entry}&pageindex=${index}&search=${search}`
      );

      dispatch({
        type: "UPDATE_ALL_USERS_SUCCESS",
        payload: data.users,
        users_list_length: data.users_list_length,
      });
    } catch (error) {
      dispatch({ type: "UPDATE_ALL_USERS_FAIL", payload: error?.message });
    }
  };

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const { data } = await axios.post(`${BASE_API_ENDPOINT}login`, {
      email: email,
      password: password,
    });

    dispatch({ type: LOGIN_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
};

// Register
export const register =
  (email, password, username, phone) => async (dispatch) => {
    try {
      dispatch({ type: REGISTER_USER_REQUEST });

      const { data } = await axios.post(`${BASE_API_ENDPOINT}register`, {
        username: username,
        email: email,
        password: password,
        phone,
      });

      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err.response.data.error,
      });
    }
  };
