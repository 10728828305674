import {
  ALL_CAMPAIGN_LIST_FAIL,
  ALL_CAMPAIGN_LIST_REQUEST,
  ALL_CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_DETAILS_FAIL,
  CAMPAIGN_DETAILS_REQUEST,
  CAMPAIGN_DETAIL_SUCCESS,
} from "../constants/constants";

const initialState = {
  loading: false,
  Campaignlist: [],
  Campaign_Detail: [],
  NotificationList: [],
  campaignData: [],
  error: "",
  length: 0,
};

export const campaignListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CAMPAIGN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        Campaignlist: action.payload,
        error: "",
      };

    case ALL_CAMPAIGN_LIST_FAIL:
      return {
        ...state,
        loading: false,
        Campaignlist: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export const campaignDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_DETAILS_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        Campaign_Detail: action.payload,
      };

    case CAMPAIGN_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        Campaign_Detail: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export const campaignStatistics = (state = { statistics: {} }, action) => {
  switch (action.type) {
    case "CAMPAIGN_DETAIL_REQUEST":
      return {
        ...state,
        statistics: {},
        error: null,
        loading: true,
      };

    case "CAMPAIGN_DETAIL_SUCCESS":
      return {
        ...state,
        statistics: action.payload,
        error: null,
        loading: false,
      };

    case "CAMPAIGN_DETAIL_FAIL":
      return {
        ...state,
        statistics: {},
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export const notificationListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_Notification_LIST_REQUEST":
    case "CAMPAIGN_DATA_REQUEST":
      return {
        ...state,

        loading: true,
      };

    case "ALL_Notification_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        NotificationList: action.payload,
        length: 0,
      };

    case "CAMPAIGN_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        campaignData: action.payload,
      };

    case "ALL_Notification_LIST_FAIL":
    case "CAMPAIGN_DATA_FAIL":
      return {
        ...state,
        loading: false,
        NotificationList: [],
        campaignData: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

const dashboardInitState = {
  campaignData: {},
  loading: false,
  error: "",
};
export const dashboardReducer = (state = dashboardInitState, action) => {
  switch (action.type) {
    case "CAMPAIGN_DATA_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "CAMPAIGN_DATA_SUCCESS":
      return {
        ...state,
        campaignData: action.payload,
        loading: false,
        error: "",
      };

    case "CAMPAIGN_DATA_FAIL":
      return {
        ...state,
        campaignData: {},
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const updateInitialState = {
  campaign_list: [],
  loading: false,
  campaign_list_length: 0,
  error: 0,
};
export const updateCampaignListReducer = (
  state = updateInitialState,
  action
) => {
  switch (action.type) {
    case "UPDATE_ALL_CAMPAIGN_LIST_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "UPDATE_ALL_CAMPAIGN_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        campaign_list: action.payload,
        campaign_list_length: action.campaign_list_length,
        error: "",
      };

    case "UPDATE_ALL_CAMPAIGN_LIST_FAIL":
      return {
        ...state,
        loading: false,
        campaign_list: [],
        campaign_list_length: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};
