export const BASENAME = "/msil-notifications"; // don't add '/' at end off BASENAME
export const BASE_URL = "/app/dashboard";
export const BASE_TITLE = " | MSIL-Notification";
const BASE_BACKEND_IP = "notificationtool.marutisuzuki.com/toolapi";

export const BASE_API_ENDPOINT = window.location.href.includes("localhost")
  ? "http://localhost:3001/api"
  : `https://${BASE_BACKEND_IP}/api`;

export const BASE_IMAGE_ENDPOINT = window.location.href.includes("localhost")
  ? "http://localhost:3001/public/images"
  : `https://${BASE_BACKEND_IP}/public/images`;

export const getRoleName = (roleId) => {
  switch (roleId) {
    case 1:
      return "Admin";

    case 2:
      return "Campaign Manager";

    case 3:
      return "Asset Manager";
      
    case 4:
      return "Zonal Manager";

    default:
      return "Admin";
  }
};

export const getWeekDays = (day) => {
  switch (day) {
    case 0:
      return "Sunday";

    case 1:
      return "Monday";

    case 2:
      return "Tuesday";

    case 3:
      return "Wednesday";

    case 4:
      return "Thursday";

    case 5:
      return "Friday";

    case 6:
      return "Saturday";

    default:
      return "Sunday";
  }
};
export const getZone = (userFilter) => {
  if (userFilter === "c")
    return [
      {
        id: "c",
        name: "Central",
      },
    ];
  else if (userFilter === "e")
    return [
      {
        id: "e",
        name: "East",
      },
    ];
  else if (userFilter === "n")
    return [
      {
        id: "n",
        name: "North",
      },
    ];
  else if (userFilter === "s")
    return [
      {
        id: "s",
        name: "South",
      },
    ];
  else if (userFilter === "w")
    return [
      {
        id: "w",
        name: "West",
      },
    ];
};

export const panIndiaZone = (flag, zone) => {
  if (flag) getZone(zone);
  return [
    {
      id: "c",
      name: "Central",
    },
    {
      id: "e",
      name: "East",
    },
    {
      id: "n",
      name: "North",
    },
    {
      id: "s",
      name: "South",
    },
    {
      id: "w",
      name: "West",
    },
  ];
};

export const regions = {
  c: [
    { id: "c1", name: "Central 1" },
    { id: "c2", name: "Central 2" },
    { id: "c3", name: "Central 3" },
    { id: "c4", name: "Central 4" },
  ],
  e: [
    { id: "e1", name: "East 1" },
    { id: "e2", name: "East 2" },
    { id: "e3", name: "East 3" },
    { id: "t2", name: "South East 2" },
  ],
  n: [
    { id: "n1", name: "North 1" },
    { id: "n2", name: "North 2" },
    { id: "n3", name: "North 3" },
    { id: "n4", name: "North 4" },
  ],
  s: [
    { id: "s1", name: "South 1" },
    { id: "s2", name: "South 2" },
    { id: "s3", name: "South 3" },
    { id: "t1", name: "South East 1" },
  ],
  w: [
    { id: "w1", name: "West 1" },
    { id: "w2", name: "West 2" },
    { id: "w3", name: "West 3" },
  ], 
};

export const getRegionNames = (zone) => {
  if (!zone) return [];
  return regions[zone];
};

export const getZoneName = (zone) => {
  if (!zone) return "All";
  return panIndiaZone().filter(({ id }) => id === zone)[0].name;
};

export const getRegionName = (region, zone) => {
  if (!region) return "All";
  return regions[zone].filter(({ id }) => id === region)[0].name;
};
export const getCampaignStatus = (status) => {
  switch (status) {
    case 0:
      return "Un-Approved";

    case 1:
      return "Approved";

    case 2:
      return "Rejected";

    case 3:
      return "Drafted";
    case 4:
      return "Stopped";

    default:
      return "Un-Approved";
  }
};
export const getAssetTypeName = (typeId) => {
  switch (typeId) {
    case 1:
      return "Web";

    case 2:
      return "Mobile App";

    case 3:
      return "Web/Mobile App";

    default:
      return "Web";
  }
};

export const CONFIG = {
  layout: "vertical", // vertical, horizontal
  subLayout: "", // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: false, // mini-menu
  layoutType: "menu-dark", // menu-dark, menu-light, dark
  navIconColor: false,
  headerBackColor: "header-default", // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: "navbar-default", // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: "brand-default", // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true, // only for vertical layouts
  headerFixedLayout: false, // only for vertical layouts
  boxLayout: false,
  navDropdownIcon: "style1", // style1, style2, style3
  navListIcon: "style1", // style1, style2, style3, style4, style5, style6
  navActiveListColor: "active-default", // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: "title-default", // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: true,
  layout6Background:
    "linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)", // used only for pre-layout = layout-6
  layout6BackSize: "", // 'auto' - for background pattern, 'cover' - for background images & used only for pre-layout = layout-6
};
