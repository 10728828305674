import {
  UPDATE_NOTIFICATION_DETAIL_REQUEST,
  UPDATE_NOTIFICATION_DETAIL_SUCCESS,
  UPDATE_NOTIFICATION_DETAIL_FAIL,
  NOTIFICATION_FETCH_REQUEST,
  NOTIFICATION_FETCH_SUCCESS,
  NOTIFICATION_FETCH_FAIL,
} from "../constants/constants";

const initialState = {
  loading: false,
  notification_detail: [],
  length: 0,
  error: '',
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        notification_detail: action.payload,
        length: action.notificationLength,
        error: "",
      };

    case UPDATE_NOTIFICATION_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        notification_detail: [],
        error: action.payload,
        length: 0,
      };

    default:
      return state;
  }
};

// UPDATE
const notificationListInitialState = {
  loading: false,
  notification_list: [],
  notification_list_length:0,
  error: "",
};

export const updatedNotificationListReducer = (
  state = notificationListInitialState,
  action
) => {
  switch (action.type) {
    case NOTIFICATION_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_FETCH_SUCCESS:
      return {
        ...state,
        notification_list: action.payload,
        loading: false,
        notification_list_length:action.notification_list_length,
        error: "",
      };

    case NOTIFICATION_FETCH_FAIL:
      return {
        ...state,
        notification_list: [],
        loading: false,
        error: action.payload,
        notification_list_length:0
      };

    default:
      return state;
  }
};
