import { BASE_API_ENDPOINT } from "../../../src/config/constant";
import axios from "../../utils/axios";
import {
  ALL_ASSET_FAIL,
  ALL_ASSET_REQUEST,
  ALL_ASSET_SUCCESS,
  ASSET_ADD_FAIL,
  ASSET_ADD_REQUEST,
  ASSET_ADD_SUCCESS,
  ASSETS_LIST_REQUEST,
  ASSETS_LIST_SUCCESS,
  ASSETS_LIST_FAIL,
  ASSETS_LIST_CLEAR,
} from "../constants/constants";


//get all assets
export const getAllAsset = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_ASSET_REQUEST });
    const { data } = await axios.get(`${BASE_API_ENDPOINT}/asset/assets`);
    dispatch({ type: ALL_ASSET_SUCCESS, payload: data.assets });
  } catch (error) {
    dispatch({ type: ALL_ASSET_FAIL, payload: error?.message });
  }
};

//UPDATE
//get all assets
export const updateGetAllAsset = () => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_ALL_ASSET_REQUEST" });
    const { data } = await axios.get(`${BASE_API_ENDPOINT}/asset/assets`);
    dispatch({ type: "UPDATE_ALL_ASSET_SUCCESS", payload: data.assets });
  } catch (error) {
    dispatch({ type: "UPDATE_ALL_ASSET_FAIL", payload: error?.message });
  }
};

//get single asset
export const getAssetDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: "ASSET_DETAILS_REQUEST" });
    const { data } = await axios.get(`${BASE_API_ENDPOINT}/asset/detail/${id}`);
    dispatch({ type: "ASSET_DETAIL_SUCCESS", payload: data.asset });
  } catch (error) {
    dispatch({
      type: "ASSET_DETAILS_FAIL",
      payload: error.response.data.message,
    });
  }
};

//delete asset
export const deleteAsset = (id) => async (dispatch) => {
  try {
    dispatch({ type: "ASSET_DELETE_REQUEST" });
    await axios.get(`${BASE_API_ENDPOINT}/asset/delete/${id}`);
    dispatch({ type: "ASSET_DELETE_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "ASSET_DELETE_FAIL",
      payload: error.response.data.message,
    });
  }
};

// Update User
export const updatAsseteUser = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_ASSET_REQUEST" });

    const { data } = await axios.put(
      `${BASE_API_ENDPOINT}/asset/update/${id}`,
      formData
    );

    dispatch({ type: "UPDATE_ASSET_SUCCESS", payload: data.asset });
  } catch (error) {
    dispatch({
      type: "UPDATE_ASSET_FAIL",
      payload: error.response.data.message,
    });
  }
};

//update status of asset
export const updateAssetStatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_ASSET_STATUS " });
    await axios.patch(
      `${BASE_API_ENDPOINT}/asset/status/update/${id}`,
      formData
    );
    dispatch({ type: "UPDATE_ASSET_STATUS_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "UPDATE_ASSET_STATUS_FAILED",
      payload: error.response.data.message,
    });
  }
};

//get active asset list
export const getAssetsList = () => async (dispatch) => {
  try {
    dispatch({ type: ASSETS_LIST_REQUEST });
    const res = await axios.get(`${BASE_API_ENDPOINT}/asset/assets`);
    dispatch({ type: ASSETS_LIST_SUCCESS, payload: res.data.assets });
  } catch (error) {
    dispatch({
      type: ASSETS_LIST_FAIL,
      payload: error.message,
    });
  }
};

//get asset list testing
export const getAssetsList1 =
  (entry = 5, index = 0, search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: ASSETS_LIST_REQUEST });
      const res = await axios.get(
        `${BASE_API_ENDPOINT}/asset/assets?entry=${entry}&pageindex=${index}&search=${search}`
      );
      dispatch({
        type: ASSETS_LIST_SUCCESS,
        payload: res.data.assets,
        assetLength: res.data.length,
      });
    } catch (error) {
      dispatch({
        type: ASSETS_LIST_FAIL,
        payload: error.response.data.message,
      });
    }
  };

//clear assets list
export const clearAssetList = () => async (dispatch) => {
  try {
    dispatch({ type: ASSETS_LIST_CLEAR });
  } catch (error) {
    dispatch({
      type: ASSETS_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};
