import {
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
} from "../constants/constants";

const initialState = {
  loading: false,
  data: [],
  error: "",
  length: 0,
};

export const allUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        length: 0,
      };

    case ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

const updateInitialState = {
  loading: false,
  data: [],
  error: "",
  users_list_length: 0,
};

export const getUpdatedAllUserReducer = (
  state = updateInitialState,
  action
) => {
  switch (action.type) {
    case "UPDATE_ALL_USERS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "UPDATE_ALL_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        users_list_length: action.users_list_length,
        error: "",
      };

    case "UPDATE_ALL_USERS_FAIL":
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        users_list_length: 0,
      };

    default:
      return state;
  }
};

export const userReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
    case "REGISTER_USER_REQUEST":
    case "LOAD_USER_REQUEST":
      return {
        loading: true,
        isAuthenticated: false,
      };

    case "FETCH_USER_DETAIL_REQUEST":
    case "UPDATE_USER_STATUS_REQUEST":
      return {
        loading: true,
      };

    case "FETCH_USER_DETAIL_SUCCESS":
    case "UPDATE_USER_STATUS_SUCCESS":
      return {
        loading: false,
        user: action.payload,
      };

    case "FETCH_USER_DETAIL_FAIL":
    case "UPDATE_USER_STATUS_FAIL":
      return {
        loading: false,
        user: [],
      };

    case "LOAD_USER_SUCCESS":
    case "LOGIN_SUCCESS":
    case "REGISTER_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case "LOGOUT_SUCCESS":
      return {
        loading: false,
        user: null,
        isAuthenticated: false,
      };

    case "LOGIN_FAIL":
    case "REGISTER_USER_FAIL":
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case "LOAD_USER_FAIL":
      return {
        loading: false,
        isLoggedIn: false,
        user: null,
        error: action.payload,
      };

    case "LOGOUT_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
