import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";

import {
  ACCOUNT_INITIALISE,
  LOGIN,
  LOGOUT,
} from "../store/constants/constants";

import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";

import axios from "../utils/axios";

import accountReducer from "../store/reducers/accountReducer";

import { getAssetsList, clearAssetList } from "../store/actions";

import Loader from "../components/Loader/Loader";

const CryptoJS = require("crypto-js");

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    localStorage.removeItem("assetID");
    delete axios.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const assetDispatch = useDispatch();
  const { addToast } = useToasts();
  const login = async (email, password) => {
    let encryptedPassword = CryptoJS.AES.encrypt(
      password,
      "e3g4rFHIccUcsd0hc1cMoJB:gXiuys",
      {
        mode: CryptoJS.mode.CBC,
        keySize: 256,
      }
    ).toString();

    const response = await axios.post("/auth/login", {
      password: encryptedPassword,
      email,
    });
    const { data, status, message } = response.data;

    if (status === "ERROR") {
      addToast(message, { appearance: "error" });
      return;
    }

    setSession(data.token);
    assetDispatch(getAssetsList());

    dispatch({
      type: LOGIN,
      payload: {
        user: response.data.data,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
    assetDispatch(clearAssetList());
  };

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get("/auth/me");
          const { data, status } = response.data;

          if (status === "ERROR") {
            dispatch({
              type: ACCOUNT_INITIALISE,
              payload: {
                isLoggedIn: false,
                user: null,
              },
            });
            return;
          }
          assetDispatch(getAssetsList());

          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user: data,
            },
          });
        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null,
          },
        });
      }
    };

    init();
  }, [assetDispatch]);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout }}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
