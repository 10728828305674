import {
  ASSETS_LIST_FAIL,
  ASSETS_LIST_SUCCESS,
  ASSETS_LIST_REQUEST,
  ASSETS_LIST_CLEAR,
  ALL_ASSET_REQUEST,
  ALL_ASSET_SUCCESS,
  ALL_ASSET_FAIL,
} from "../constants/constants";

export const assetReducer = (state = { asset: {} }, action) => {
  switch (action.type) {
    case "UPDATE_ASSET_REQUEST":
      return {
        loading: true,
      };

    case "UPDATE_ASSET_SUCCESS":
      return {
        ...state,
        loading: false,
        asset: action.payload,
      };

    case "UPDATE_ASSET_FAIL":
      return {
        ...state,
        loading: false,
        asset: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
const initState = {
  loading: false,
  assets: [],
  error: "",
};
export const allAssetReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_ALL_ASSET_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "UPDATE_ALL_ASSET_SUCCESS":
      return {
        ...state,
        loading: false,
        assets: action.payload,
        error: "",
      };

    case "UPDATE_ALL_ASSET_FAIL":
      return {
        ...state,
        assets: [],
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

//single asset detail
export const assetDetailsReducer = (state = { asset: {} }, action) => {
  switch (action.type) {
    case "ASSET_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ASSET_DETAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        asset: action.payload,
      };

    case "ASSET_DETAILS_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

let initialListAssets = {
  list: [],
  loading: false,
  hasError: false,
  message: null,
  length: 0,
};

export const getAssetsList = (state = initialListAssets, action) => {
  switch (action.type) {
    case ASSETS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ASSETS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        length: action.assetLength,
        list: action.payload,
      };

    case ASSETS_LIST_FAIL:
      return {
        ...state,
        list: [],
        hasError: true,
        message: action.payload,
        loading: false,
      };
    case ASSETS_LIST_CLEAR:
      return {
        ...state,
        list: [],
        hasError: false,
        message: null,
        loading: false,
      };

    default:
      return state;
  }
};
