const menuItems = {
  items: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "single-item",
          icon: "feather icon-home",
          url: "/app/dashboard",
        },
      ],
    },

    {
      id: "assets",
      title: "Assets",
      type: "group",
      icon: "",
      children: [
        {
          id: "assets",
          title: "Assets",
          type: "single-item",
          icon: "feather icon-command",
          url: "/assets/assetslist",
          children: [
            {
              id: "assetslist",
              title: "Assets List",
              type: "item",
              url: "/assets/assetslist",
            },
          ],
        },
      ],
    },
    {
      id: "campaign",
      title: "Campaigns",
      type: "group",
      icon: "",
      children: [
        {
          id: "campaign",
          title: "Campaigns",
          type: "collapse",
          icon: "fa fa-bullhorn",
          children: [
            {
              id: "campaignview",
              title: "View Campaign",
              type: "item",
              url: "/campaign/campaignView",
              icon: "fa fa-list",
            },
          ],
        },
      ],
    },

    // {
    //   id: "faqs",
    //   title: "FAQ",
    //   type: "group",
    //   icon: "",
    //   children: [
    //     {
    //       id: "faqs",
    //       title: "FAQ",
    //       type: "single-item",
    //       icon: "feather icon-help-circle",
    //       url: "/faq/faq-list",

    //       // children: [
    //       //   {
    //       //     id: "faq",
    //       //     title: "FAQ List",
    //       //     type: "item",
    //       //     url: "/faq/faq-list",
    //       //   },
    //       // ],
    //     },
    //   ],
    // },
  ],
};

export default menuItems;
