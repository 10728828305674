import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ListGroup, Dropdown, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import ChatList from "./ChatList";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import useAuth from "../../../../hooks/useAuth";
import avatar1 from "../../../../assets/images/user/avatar-1.jpg";

import { Form, Button } from "react-bootstrap";
import { getRoleName } from "../../../../config/constant";
import { AiFillEdit } from "react-icons/ai";
import axios from "../../../../utils/axios";
import { BASE_API_ENDPOINT } from "../../../../config/constant";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const NavRight = () => {
  const usernameRef = useRef(null);
  const phoneRef = useRef(null);
  const { addToast } = useToasts();
  const configContext = useContext(ConfigContext);
  const { user, isLoggedIn, logout } = useAuth();
  const { rtlLayout } = configContext.state;
  const [lgShow, setLgShow] = useState(false);
  const [cpShow, setCpShow] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [oldPassword, setpreviosPswd] = useState("");
  const [newPassword, setNewPswd] = useState("");
  const [confirmPassword, setConfirmNewPswd] = useState("");
  const [isOldPasswordError, setIsOldPasswordError] = useState(false);
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isUserNameError, setIsUserNameError] = useState(false);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [userNameError, setUserNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [username, setUsername] = useState(user.username);
  const [phone, setPhoneNo] = useState(user.phone);
  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);

  const handleLogout = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Logout",
      html: (
        <div style={{ textAlign: "center" }}>
          <p>Are you sure you want to logout?</p>
        </div>
      ),

      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonColor: "#2d3393",
      confirmButtonText: "Logout",
      confirmButtonColor: "#2d3393",
    }).then((willLogout) => {
      if (willLogout.value) {
        logout();
      }
    });
  };

  const handleOnHide = () => {
    setCpShow(false);
    setpreviosPswd("");
    setNewPswd("");
    setConfirmNewPswd("");
    setOldPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");
    setIsOldPasswordError(false);
    setIsNewPasswordError(false);
    setIsConfirmPasswordError(false);
    setIsValueChanged(false);
  };

  const handleLogin = () => {
    history.push("/auth/login");
  };
  const handleOnClick = async () => {
    if (oldPassword.length === 0) {
      setIsOldPasswordError(true);
      setOldPasswordError("Enter your old Password");
    } else if (newPassword.length === 0 || newPassword.length < 8) {
      setOldPasswordError(false);
      setIsNewPasswordError(true);
      setNewPasswordError("Enter Password of 8 digits");
    } else if (newPassword === oldPassword) {
      setOldPasswordError(false);
      setIsNewPasswordError(true);
      setNewPasswordError("New password must be different from old passsword");
    } else if (
      confirmPassword.length === 0 ||
      confirmPassword.length < 8 ||
      newPassword !== confirmPassword
    ) {
      setOldPasswordError(false);
      setNewPasswordError(false);
      setIsConfirmPasswordError(true);
      setConfirmPasswordError("Confirm Password must match New Password");
    } else {
      setIsOldPasswordError(false);
      setIsNewPasswordError(false);
      setIsConfirmPasswordError(false);
      setOldPasswordError("");
      setNewPasswordError("");
      setConfirmPasswordError("");
      setButtonDisable(true);

      const id = user._id;
      const { data } = await axios.put(
        `${BASE_API_ENDPOINT}/auth/updateUserPassword/${id}`,
        { newPassword, oldPassword, confirmPassword }
      );

      const { status, message } = data;
      if (status === "ERROR") {
        addToast(message, { appearance: "warning" });
        setButtonDisable(false);
        return;
      }
      setButtonDisable(false);
      addToast(message, { appearance: "success" });
      setCpShow(!cpShow);
      setpreviosPswd("");
      setConfirmNewPswd("");
      setNewPswd("");
    }
  };
  const handleProfileClick = () => {
    setIsOldPasswordError(false);
    setIsNewPasswordError(false);
    setIsConfirmPasswordError(false);
    setOldPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");
    setUsername(username);
    setPhoneNo(phone);
    setLgShow(!lgShow);
  };
  const handleUserName = () => {
    usernameRef.current.focus();
  };
  const handlePhoneNumber = () => {
    phoneRef.current.focus();
  };
  const handleOnHideProfile = () => {
    setLgShow(false);
    setIsUserNameError(false);
    setIsPhoneNumberError(false);
    setUserNameError("");
    setPhoneNumberError("");
  };

  const handleButtonCLick = async () => {
    setIsValueChanged(false);
    if (username.length === 0) {
      setIsUserNameError(true);
      setUserNameError("Enter Username");
    } else if (phone.toString().length !== 10) {
      setIsUserNameError(false);
      setIsPhoneNumberError(true);
      setPhoneNumberError("Phone number must be 10 digits");
    } else {
      setIsUserNameError(false);
      setIsPhoneNumberError(false);
      setUserNameError("");
      setPhoneNumberError("");
      setButtonDisable(true);
      const id = user._id;
      const { data } = await axios.put(
        `${BASE_API_ENDPOINT}/auth/updateUserDetail/${id}`,
        { username, phone }
      );
      const { status, message } = data;
      if (status === "ERROR") {
        addToast(message, { appearance: "warning" });
        setButtonDisable(false);
        return;
      }
      setButtonDisable(false);
      setLgShow(!lgShow);
      setUsername(data.user.username);
      setPhoneNo(data.user.phone);
      setIsValueChanged(false);
      addToast(message, { appearance: "success" });
    }
  };
  useEffect(() => {
    oldPassword && newPassword && confirmPassword
      ? setDisableButton(false)
      : setDisableButton(true);
  }, [oldPassword, newPassword, confirmPassword]);

  return (
    <React.Fragment>
      <ListGroup
        as="ul"
        bsPrefix=" "
        className="navbar-nav ml-auto"
        id="navbar-right"
      >
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              <i className="icon feather icon-user" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head d-flex flex-row bd-highlight mb-3">
                <div>
                  <img
                    src={avatar1}
                    className="img-radius"
                    alt="User Profile"
                  />
                </div>

                <div>
                  <span>{`${username}`}</span>
                  <br />

                  <span>{getRoleName(user.role)} </span>
                </div>
              </div>

              <ListGroup
                as="ul"
                bsPrefix=" "
                variant="flush"
                className="pro-body"
              >
                <ListGroup.Item
                  as="li"
                  bsPrefix=" "
                  onClick={handleProfileClick}
                >
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item>

                <ListGroup.Item
                  as="li"
                  bsPrefix=" "
                  onClick={() => setCpShow(!cpShow)}
                >
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-lock" /> Change Password
                  </Link>
                </ListGroup.Item>

                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-lock" /> Lock Screen
                  </Link>
                </ListGroup.Item> */}
                {isLoggedIn ? (
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      <i className="feather icon-log-out" /> Logout
                    </Link>
                  </ListGroup.Item>
                ) : (
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={handleLogin}
                    >
                      <i className="feather icon-log-in" /> Login
                    </Link>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />

      <Modal
        size="lg"
        show={lgShow}
        onHide={handleOnHideProfile}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            My Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>FullName</Form.Label>
                <AiFillEdit
                  size="1.5rem"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    color: "#2d3393",
                  }}
                  onClick={handleUserName}
                />

                <Form.Control
                  type="text"
                  name="recipient"
                  value={username}
                  // defaultValue={user.username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setIsValueChanged(true);
                  }}
                  ref={usernameRef}
                />
                {isUserNameError && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {userNameError}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="recipient"
                  defaultValue={user.email}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Phone-Number</Form.Label>
                <AiFillEdit
                  size="1.5rem"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    color: "#2d3393",
                  }}
                  onClick={handlePhoneNumber}
                />

                <Form.Control
                  type="text"
                  name="recipient"
                  value={phone}
                  onChange={(e) => {
                    setPhoneNo(e.target.value);
                    setIsValueChanged(true);
                  }}
                  ref={phoneRef}
                />
                {isPhoneNumberError && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {phoneNumberError}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  type="text"
                  name="recipient"
                  placeholder="Enter Old Password"
                  defaultValue={getRoleName(user.role)}
                  readOnly
                />
              </Form.Group>
            </Form>
          </Fragment>
          <div className="text-center mt-3 mb-2">
            {buttonDisable ? (
              <Button disabled>
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
                Updating...
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={!isValueChanged}
                onClick={handleButtonCLick}
              >
                Update Details
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={cpShow}
        onHide={handleOnHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Previous Password</Form.Label>
              <Form.Control
                type="password"
                name="recipient"
                placeholder="Enter Previous Password"
                value={oldPassword}
                onChange={(e) => setpreviosPswd(e.target.value)}
              />
              {isOldPasswordError && (
                <small className="text-danger form-text text-left">
                  {oldPasswordError}
                </small>
              )}
            </Form.Group>
            <Form.Group controlId="formBasiccpassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="recipient"
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPswd(e.target.value)}
              />
              {isNewPasswordError && (
                <small className="text-danger form-text text-left">
                  {newPasswordError}
                </small>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicpassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="recipient"
                placeholder="Enter New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmNewPswd(e.target.value)}
              />
              {isConfirmPasswordError && (
                <small className="text-danger form-text text-left">
                  {confirmPasswordError}
                </small>
              )}
            </Form.Group>
          </Form>

          <div className="text-center">
            {buttonDisable ? (
              <Button disabled>
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
                Loading...
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={disableButton}
                onClick={handleOnClick}
              >
                Change Password
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default NavRight;
