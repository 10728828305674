import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import { allUserReducer, getUpdatedAllUserReducer, userReducer } from "./reducers/userReducer";
import {
  allAssetReducer,
  assetReducer,
  assetDetailsReducer,
  getAssetsList,
} from "./reducers/assetReducer";
import {
  campaignDetailReducer,
  campaignListReducer,
  campaignStatistics,
  dashboardReducer,
  notificationListReducer,
  updateCampaignListReducer,
} from "./reducers/campaignReducer";
import {
  notificationReducer,
  updatedNotificationListReducer,
} from "./reducers/notificationReducer";

const reducers = combineReducers({
  form: formReducer,
  loggedUser: userReducer,
  allUser: allUserReducer,
  newAsset: assetReducer,
  assetInfo: assetReducer,
  allAssets: allAssetReducer,
  assetDetail: assetDetailsReducer,
  userDetail: userReducer,
  assetList: getAssetsList,
  Campaign_List: campaignListReducer,
  CampaignDetail: campaignDetailReducer,
  Notifications: notificationListReducer,
  Notification_Detail: notificationReducer,
  campaign_data: notificationListReducer,
  campaign_statistics: campaignStatistics,
  update_notification: updatedNotificationListReducer,
  dashboard_campaign_data:dashboardReducer,
  updated_Campaign_List:updateCampaignListReducer,
  updatedUserList:getUpdatedAllUserReducer
});

export default reducers;
