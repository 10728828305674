import axios from "axios";
import { BASE_API_ENDPOINT } from "../../src/config/constant";

const axiosServices = axios.create({
  baseURL: BASE_API_ENDPOINT,
});

axiosServices.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject((error.response && error.response.data) || "Wrong Services")
);

export default axiosServices;
