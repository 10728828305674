import React, { useContext } from "react";
import { Link } from "react-router-dom";
import marutilogo from "../../../../assets/images/marutilogo/maruti-logo.png";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/constants/constants";

const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;
  const styling = { backgroundColor: "white" };

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo" style={styling}>
				<Link
					to="#"
					className={toggleClass.join(' ')}
					id="mobile-collapse"
					onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
				>
					<span />
				</Link>
				<span className="b-title">
					<img className="b-image" src={marutilogo} alt="Maruti Logo" />
				</span>
			</div>
    </React.Fragment>
  );
};

export default NavLogo;
