import axios from "../../utils/axios";
import {
  ALL_CAMPAIGN_LIST_FAIL,
  ALL_CAMPAIGN_LIST_REQUEST,
  ALL_CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_DETAILS_FAIL,
  CAMPAIGN_DETAILS_REQUEST,
  CAMPAIGN_DETAIL_SUCCESS,
} from "../constants/constants";
import { BASE_API_ENDPOINT } from "../../config/constant";

//actual
export const getCampaignList = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_CAMPAIGN_LIST_REQUEST });

    const { data } = await axios.get(
      `${BASE_API_ENDPOINT}/campaign/campaign-list`
    );
    dispatch({ type: ALL_CAMPAIGN_LIST_SUCCESS, payload: data.campaignList });
  } catch (error) {
    dispatch({
      type: ALL_CAMPAIGN_LIST_FAIL,
      payload: error?.message,
    });
  }
};

//Updated
export const updatedGetCampaignList =
  (entry = 5, index = 0, search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_ALL_CAMPAIGN_LIST_REQUEST" });

      const { data } = await axios.get(
        `${BASE_API_ENDPOINT}/campaign/campaign-list-update?entry=${entry}&pageindex=${index}&search=${search}`
      );
      dispatch({
        type: "UPDATE_ALL_CAMPAIGN_LIST_SUCCESS",
        payload: data.campaignList,
        campaign_list_length: data.campaign_list_length,
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_ALL_CAMPAIGN_LIST_FAIL",
        payload: error?.message,
      });
    }
  };

//testing
export const getCampaignList1 =
  (entry = 5, index = 0, search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_CAMPAIGN_LIST_REQUEST });

      const { data } = await axios.get(
        `${BASE_API_ENDPOINT}/campaign/campaign-list?entry=${entry}&pageindex=${index}&search=${search}`
      );

      dispatch({
        type: ALL_CAMPAIGN_LIST_SUCCESS,
        payload: data.campaignList,
        campaignLength: data.campaignLength,
      });
    } catch (error) {
      dispatch({
        type: ALL_CAMPAIGN_LIST_FAIL,
        payload: error.response.data.message,
      });
    }
  };

//get single asset
export const getCampaignDetailsByID = (id) => async (dispatch) => {
  try {
    dispatch({ type: CAMPAIGN_DETAILS_REQUEST });
    const { data } = await axios.get(
      `${BASE_API_ENDPOINT}/campaign/get-campaign-by-id/${id}`
    );
    dispatch({ type: CAMPAIGN_DETAIL_SUCCESS, payload: data.campaign });
  } catch (error) {
    dispatch({
      type: CAMPAIGN_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

//notification list
export const getNotificationList = () => async (dispatch) => {
  try {
    dispatch({ type: "ALL_Notification_LIST_REQUEST" });

    const { data } = await axios.get(
      `${BASE_API_ENDPOINT}/campaign/notifications`
    );
    dispatch({
      type: "ALL_Notification_LIST_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "ALL_Notification_LIST_FAIL",
      payload: error?.message,
    });
  }
};

//testing
export const getNotificationList1 =
  (entry = 5, index = 0, search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "ALL_Notification_LIST_REQUEST" });

      const { data } = await axios.get(
        `${BASE_API_ENDPOINT}/campaign/notifications?entry=${entry}&pageindex=${index}&search=${search}`
      );
      dispatch({
        type: "ALL_Notification_LIST_SUCCESS",
        payload: data.data,
        notificationsLength: data.length,
      });
    } catch (error) {
      dispatch({
        type: "ALL_Notification_LIST_FAIL",
        payload: error.response.data.message,
      });
    }
  };

//campaign Data
export const getCampaignData = () => async (dispatch) => {
  try {
    dispatch({ type: "CAMPAIGN_DATA_REQUEST" });

    const { data } = await axios.get(
      `${BASE_API_ENDPOINT}/dashboard/campaignData`
    );
    dispatch({
      type: "CAMPAIGN_DATA_SUCCESS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "CAMPAIGN_DATA_FAIL",
      payload: error.message,
    });
  }
};

//Campaign Data fro statistics
export const getCampaignStatistic = () => async (dispatch) => {
  try {
    dispatch({ type: "CAMPAIGN_DETAIL_REQUEST" });

    const { data } = await axios.get(
      `${BASE_API_ENDPOINT}/campaign/get-campaigns-data`
    );
    dispatch({
      type: "CAMPAIGN_DETAIL_SUCCESS",
      payload: data,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: "CAMPAIGN_DETAIL_FAIL",
      payload: error.message,
    });
  }
};
