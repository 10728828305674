export const BOX_LAYOUT = "BOX_LAYOUT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_SUB_LAYOUT = "CHANGE_SUB_LAYOUT";
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const CONFIG_BLOCK = "CONFIG_BLOCK";
export const HEADER_BACK_COLOR = "HEADER_BACK_COLOR";
export const HEADER_FIXED_LAYOUT = "HEADER_FIXED_LAYOUT";
export const LAYOUT_TYPE = "LAYOUT_TYPE";
export const LAYOUT6_BACKGROUND = "LAYOUT6_BACKGROUND";
export const NAV_ACTIVE_LIST_COLOR = "NAV_ACTIVE_LIST_COLOR";
export const NAV_BRAND_COLOR = "NAV_BRAND_COLOR";
export const NAV_BACK_COLOR = "NAV_BACK_COLOR";
export const NAV_BACK_IMAGE = "NAV_BACK_IMAGE";
export const NAV_DROPDOWN_ICON = "NAV_DROPDOWN_ICON";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_FIXED_LAYOUT = "NAV_FIXED_LAYOUT";
export const NAV_ICON_COLOR = "NAV_ICON_COLOR";
export const NAV_LIST_ICON = "NAV_LIST_ICON";
export const NAV_LIST_TITLE_COLOR = "NAV_LIST_TITLE_COLOR";
export const NAV_LIST_TITLE_HIDE = "NAV_LIST_TITLE_HIDE";
export const RESET = "RESET";
export const RTL_LAYOUT = "RTL_LAYOUT";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_INITIALISE = "ACCOUNT_INITIALISE";
export const FIREBASE_STATE_CHANGED = "FIREBASE_STATE_CHANGED";

//user constants
export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

//Assets constants
export const party_types = ["U", "TR", "M", "MS"];

export const ASSETS_LIST_REQUEST = "ASSETS_LIST_REQUEST";
export const ASSETS_LIST_SUCCESS = "ASSETS_LIST_SUCCESS";
export const ASSETS_LIST_FAIL = "ASSETS_LIST_FAIL";
export const ASSETS_LIST_CLEAR = "ASSETS_LIST_CLEAR";

export const ASSET_ADD_REQUEST = "ASSET_ADD_REQUEST";
export const ASSET_ADD_SUCCESS = "ASSET_ADD_SUCCESS";
export const ASSET_ADD_FAIL = "ASSET_ADD_FAIL";

export const ALL_ASSET_REQUEST = "ALL_ASSET_REQUEST";
export const ALL_ASSET_SUCCESS = "ALL_ASSET_SUCCESS";
export const ALL_ASSET_FAIL = "ALL_ASSET_FAIL";

//campaign constants
export const ALL_CAMPAIGN_LIST_REQUEST = "ALL_CAMPAIGN_LIST_REQUEST";
export const ALL_CAMPAIGN_LIST_SUCCESS = "ALL_CAMPAIGN_LIST_SUCCESS";
export const ALL_CAMPAIGN_LIST_FAIL = "ALL_CAMPAIGN_LIST_FAIL";
export const CAMPAIGN_DETAILS_REQUEST = "CAMPAIGN_DETAILS_REQUEST";
export const CAMPAIGN_DETAIL_SUCCESS = "CAMPAIGN_DETAIL_SUCCESS";
export const CAMPAIGN_DETAILS_FAIL = "CAMPAIGN_DETAILS_FAIL";
export const CAMPAIGN_NAME = "CAMPAIGN_NAME";
export const ASSET_MANAGER_NAME = "ASSET_MANAGER_NAME";
export const ASSET_NAME = "ASSET_NAME";
export const TITLE = "TITLE";
export const MEDIA_LINK = "MEDIA_LINK";
export const NOTIFICATION_BODY = "NOTIFICATION_BODY";
export const SEGMENT = "SEGMENT";
export const SEGMENT_TYPE = "SEGMENT_TYPE";
export const DATE = "DATE";
export const ASSET_LIST = "ASSET_LIST";
export const TITLE_ERROR = "TITLE_ERROR";
export const TEXT_MESSAGE_ERROR = "TEXT_MESSAGE_ERROR";
export const DATE_ERROR = "DATE_ERROR";
export const ASSET_MANAGER_ERROR = "ASSET_MANAGER_ERROR";
export const ASSET_ERROR = "ASSET_ERROR";
export const CAMPAIGN_NAME_ERROR = "CAMPAIGN_NAME_ERROR";
export const SEGMENT_TYPE_ERROR = "SEGMENT_TYPE_ERROR";
export const FILE_SIZE_ERROR = "FILE_SIZE_ERROR";
export const SEGMENT_ERROR = "SEGMENT_ERROR";
export const NOTIFICATION_MESSAGE_ERROR = "NOTIFICATION_MESSAGE_ERROR";
export const IS_FILE_SIZE_ERROR = "IS_FILE_SIZE_ERROR";

export const IS_DATE_ERROR = "IS_DATE_ERROR";
export const IS_NOTIFICATION_MESSAGE_ERROR = "IS_NOTIFICATION_MESSAGE_ERROR";
export const IS_SEGMENT_IMAGE_SET = "IS_SEGMENT_IMAGE_SET";
export const IS_SEGMENT_ERROR = "IS_SEGMENT_ERROR";
export const IS_SEGMENT_TYPE_ERROR = "IS_SEGMENT_TYPE_ERROR";
export const IS_CAMPAIGN_NAME_ERROR = "IS_CAMPAIGN_NAME_ERROR";
export const IS_ASSET_ERROR = "IS_ASSET_ERROR";
export const IS_SEGMENT_SELECTED = "IS_SEGMENT_SELECTED";
export const IS_ASSET_MANAGER_ERROR = "IS_ASSET_MANAGER_ERROR";
export const IS_TEXT_MESSAGE_ERROR = "IS_TEXT_MESSAGE_ERROR";
export const IS_TITLE_ERROR = "IS_TITLE_ERROR";
export const IS_IMAGE_SET = "IS_IMAGE_SET";
export const IS_DISPLAY = "IS_DISPLAY";
export const IS_CHECKED = "IS_CHECKED";
export const IS_BUTTON_DISABLE = "IS_BUTTON_DISABLE";

export const IS_NOTIFICATION_TYPE_ERROR = "IS_NOTIFICATION_TYPE_ERROR";
export const NOTIFICATION_TYPE = "NOTIFICATION_TYPE";
export const NOTIFICATION_TYPE_ERROR = "NOTIFICATION_TYPE_ERROR";

export const NOTIFICATION_DETAIL_REQUEST = "NOTIFICATION_DETAIL_REQUEST";
export const NOTIFICATION_DETAIL_SUCCESS = "NOTIFICATION_DETAIL_SUCCESS";
export const NOTIFICATION_DETAIL_FAIL = "NOTIFICATION_DETAIL_FAIL";

// UDATED NOTIFICATION LIST
export const NOTIFICATION_FETCH_REQUEST = "NOTIFICATION_FETCH_REQUEST";
export const NOTIFICATION_FETCH_SUCCESS = "NOTIFICATION_FETCH_SUCCESS";
export const NOTIFICATION_FETCH_FAIL = "NOTIFICATION_FETCH_FAIL";

export const UPDATE_NOTIFICATION_DETAIL_REQUEST =
  "UPDATE_NOTIFICATION_DETAIL_REQUEST";
export const UPDATE_NOTIFICATION_DETAIL_SUCCESS =
  "UPDATE_NOTIFICATION_DETAIL_SUCCESS";
export const UPDATE_NOTIFICATION_DETAIL_FAIL =
  "UPDATE_NOTIFICATION_DETAIL_FAIL";
