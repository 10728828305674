const menuItems = {
  items: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "single-item",
          icon: "feather icon-home",
          url: "/app/dashboard",
        },
      ],
    },

    {
      id: "campaign",
      title: "Campaigns",
      type: "group",
      icon: "",
      children: [
        {
          id: "campaign",
          title: "Campaigns",
          type: "collapse",
          icon: "fa fa-bullhorn",
          children: [
            {
              id: "campaignadd",
              title: "Add Campaign",
              type: "item",
              url: "/campaign/campaignadd",
              icon: "fa fa-plus",
            },
            {
              id: "campaignview",
              title: "View Campaign",
              type: "item",
              url: "/campaign/campaignView",
              icon: "fa fa-list",
            },
          ],
        },
      ],
    },

    {
      id: "notifications",
      title: "Notifications",
      type: "group",
      icon: "",
      children: [
        {
          id: "notifications",
          title: "Notifications",
          type: "single-item",
          icon: "feather icon-bell",
          url: "/notifications/notification-list",
          children: [
            {
              id: "notificationslist",
              title: "Notification List",
              type: "item",
              url: "/notifications/notification-list",
            },
          ],
        },
      ],
    },
  ],
};

export default menuItems;
