import React, { useState, useEffect, useContext } from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import navigation from "../../../Menu/menu-items";
import AssetNavigation from "../../../Menu/admin-menu-items";
import compaignNavigation from "../../../Menu/campaign-menu-item";
import { BASE_TITLE, BASENAME } from "../../../config/constant";

import { ConfigContext } from "../../../contexts/ConfigContext";
import * as actionType from "../../../store/constants/constants";

const Breadcrumb = () => {
  const [main, setMain] = useState([]);
  const [item, setItem] = useState([]);
  const location = useLocation();
  const configContext = useContext(ConfigContext);
  const { dispatch } = configContext;

  useEffect(() => {
    navigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        getCollapse(item, index);
      }
      return false;
    });

    AssetNavigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        getCollapse(item, index);
      }
      return false;
    });

    compaignNavigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        getCollapse(item, index);
      }
      return false;
    });
  });

  const getCollapse = (item, index) => {
    if (item.children) {
      item.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse, index);
        } else if (collapse.type && collapse.type === "item") {
          if (document.location.pathname === BASENAME + collapse.url) {
            setMain(item);
            setItem(collapse);
          }
        } else if (collapse.type && collapse.type === "single-item") {
          if (document.location.pathname === BASENAME + collapse.url) {
            setMain(item);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  let mainContent, itemContent;
  let breadcrumbContent = "";
  let title = "";
  if (main && main.type === "collapse") {
    mainContent = (
      <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
        {main.title}
      </ListGroup.Item>
    );
  }

  if (item && item.type === "item") {
    title = item.title;
    itemContent = (
      <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
        <Link to="#">{title}</Link>
      </ListGroup.Item>
    );

    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title">
                  <h5 className="m-b-10">
                    {location.pathname !== "/app" && title}
                  </h5>
                </div>
                <ListGroup as="ul" bsPrefix=" " className="breadcrumb">
                  <ListGroup.Item
                    onClick={() =>
                      dispatch({
                        type: actionType.COLLAPSE_TOGGLE,
                        menu: { id: "dashboard", type: "main" },
                      })
                    }
                    as="li"
                    bsPrefix=" "
                    className="breadcrumb-item"
                  >
                    <Link to="/">
                      <i className="feather icon-home" />
                    </Link>
                  </ListGroup.Item>
                  {location.pathname !== "/app" && mainContent}
                  {location.pathname !== "/app" && itemContent}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      );
    }

    document.title = title + BASE_TITLE;
  }

  if (item && item.type === "single-item") {
    title = item.title;
    itemContent = (
      <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
        <Link to="#">{title}</Link>
      </ListGroup.Item>
    );

    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title">
                  <h5 className="m-b-10">
                    {location.pathname !== "/app" && title}
                  </h5>
                </div>
                <ListGroup as="ul" bsPrefix=" " className="breadcrumb">
                  <ListGroup.Item
                    onClick={() =>
                      dispatch({
                        type: actionType.COLLAPSE_TOGGLE,
                        menu: { id: "dashboard", type: "main" },
                      })
                    }
                    as="li"
                    bsPrefix=" "
                    className="breadcrumb-item"
                  >
                    <Link to="/">
                      <i className="feather icon-home" />
                    </Link>
                  </ListGroup.Item>
                  {location.pathname !== "/app" && mainContent}
                  {location.pathname !== "/app" && itemContent}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      );
    }

    document.title = title + BASE_TITLE;
  }

  return <React.Fragment>{breadcrumbContent}</React.Fragment>;
};

export default Breadcrumb;
